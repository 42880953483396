.joint-element.highlighted rect {
	stroke-width: 3
}

.joint-element.highlighted circle {
	stroke-width: 3
}

.joint-element.highlighted path {
	stroke-width: 3
}

.joint-link.highlighted path {
	stroke-width: 3
}

/*threat model status indicator classes*/

.isOutOfScope {
    stroke-dasharray: 5,2;
    fill: none
}

.hasOpenThreats {
    stroke: red;
}

.marker-target.hasOpenThreats {
    fill: red
}

text.hasOpenThreats  {
    stroke: none;
    fill: red
}

/*threat model element tools*/

.joint-element .element-tools {
	display: none;
	cursor: pointer
}

.joint-element.highlighted .element-tools {
	display: inline;
}

.element-tool-link circle {
	fill: grey;
}

.element-tool-link.linking circle {
	fill: green;
}

/*threat model link tools*/

.joint-type-tm-flow .link-tool .tool-options {
	display: inline;
}

.joint-type-tm-flow .labels .label text {
	font-weight: 400;
	font-size: small
}

/* responsive paper */

.tmt-diagram-container {
	height: 100%;
	width: 100%;
	overflow-x: auto;
	overflow-y: auto;
}

/*stencil*/

.stencil .joint-element, .stencil .joint-link, .stencil .joint-link .connection-wrap {
	cursor: pointer;
}

.stencil .link-tool, .stencil .marker-arrowheads {
	visibility: hidden;
}

.stencil .joint-link:hover, .stencil .joint-element:hover path, .stencil .joint-element:hover rect, .stencil .joint-element:hover circle {
	stroke-width: 3;
}