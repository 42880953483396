.cardd10, .cardd2, .cardd3, .cardd4, .cardd5, 
.cardd6, .cardd7, .cardd8, .cardd9, .cardda, 
.carddj, .carddk, .carddq, .carde10, .carde5, 
.carde6, .carde7, .carde8, .carde9, .cardea, 
.cardej, .cardek, .cardeq, .cardi10, .cardi2, 
.cardi3, .cardi4, .cardi5, .cardi6, .cardi7, 
.cardi8, .cardi9, .cardia, .cardij, .cardik, 
.cardiq, .cardr10, .cardr2, .cardr3, .cardr4, 
.cardr5, .cardr6, .cardr7, .cardr8, .cardr9, 
.cardra, .cardrj, .cardrk, .cardrq, .cards10, 
.cards2, .cards3, .cards4, .cards5, .cards6, 
.cards7, .cards8, .cards9, .cardsa, .cardsj, 
.cardsk, .cardsq, .cardt10, .cardt3, .cardt4, 
.cardt5, .cardt6, .cardt7, .cardt8, .cardt9, 
.cardta, .cardtj, .cardtk, .cardtq
{ display: inline-block; background: url('/cards.png?v2.0') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }
 
.cardd10 { background-position: -5px -0px; width: 300px; height: 518px; }
.cardd2 { background-position: -310px -0px; width: 300px; height: 518px; }
.cardd3 { background-position: -615px -0px; width: 300px; height: 518px; }
.cardd4 { background-position: -920px -0px; width: 300px; height: 518px; }
.cardd5 { background-position: -1225px -0px; width: 300px; height: 518px; }
.cardd6 { background-position: -1530px -0px; width: 300px; height: 518px; }
.cardd7 { background-position: -1835px -0px; width: 300px; height: 518px; }
.cardd8 { background-position: -2140px -0px; width: 300px; height: 518px; }
.cardd9 { background-position: -5px -523px; width: 300px; height: 518px; }
.cardda { background-position: -310px -523px; width: 300px; height: 518px; }
.carddj { background-position: -615px -523px; width: 300px; height: 518px; }
.carddk { background-position: -920px -523px; width: 300px; height: 518px; }
.carddq { background-position: -1225px -523px; width: 300px; height: 518px; }
.carde10 { background-position: -1530px -523px; width: 300px; height: 518px; }
.carde5 { background-position: -1835px -523px; width: 300px; height: 518px; }
.carde6 { background-position: -2140px -523px; width: 300px; height: 518px; }
.carde7 { background-position: -5px -1046px; width: 300px; height: 518px; }
.carde8 { background-position: -310px -1046px; width: 300px; height: 518px; }
.carde9 { background-position: -615px -1046px; width: 300px; height: 518px; }
.cardea { background-position: -920px -1046px; width: 300px; height: 518px; }
.cardej { background-position: -1225px -1046px; width: 300px; height: 518px; }
.cardek { background-position: -1530px -1046px; width: 300px; height: 518px; }
.cardeq { background-position: -1835px -1046px; width: 300px; height: 518px; }
.cardi10 { background-position: -2140px -1046px; width: 300px; height: 518px; }
.cardi2 { background-position: -5px -1569px; width: 300px; height: 518px; }
.cardi3 { background-position: -310px -1569px; width: 300px; height: 518px; }
.cardi4 { background-position: -615px -1569px; width: 300px; height: 518px; }
.cardi5 { background-position: -920px -1569px; width: 300px; height: 518px; }
.cardi6 { background-position: -1225px -1569px; width: 300px; height: 518px; }
.cardi7 { background-position: -1530px -1569px; width: 300px; height: 518px; }
.cardi8 { background-position: -1835px -1569px; width: 300px; height: 518px; }
.cardi9 { background-position: -2140px -1569px; width: 300px; height: 518px; }
.cardia { background-position: -5px -2092px; width: 300px; height: 518px; }
.cardij { background-position: -310px -2092px; width: 300px; height: 518px; }
.cardik { background-position: -615px -2092px; width: 300px; height: 518px; }
.cardiq { background-position: -920px -2092px; width: 300px; height: 518px; }
.cardr10 { background-position: -1225px -2092px; width: 300px; height: 518px; }
.cardr2 { background-position: -1530px -2092px; width: 300px; height: 518px; }
.cardr3 { background-position: -1835px -2092px; width: 300px; height: 518px; }
.cardr4 { background-position: -2140px -2092px; width: 300px; height: 518px; }
.cardr5 { background-position: -5px -2615px; width: 300px; height: 518px; }
.cardr6 { background-position: -310px -2615px; width: 300px; height: 518px; }
.cardr7 { background-position: -615px -2615px; width: 300px; height: 518px; }
.cardr8 { background-position: -920px -2615px; width: 300px; height: 518px; }
.cardr9 { background-position: -1225px -2615px; width: 300px; height: 518px; }
.cardra { background-position: -1530px -2615px; width: 300px; height: 518px; }
.cardrj { background-position: -1835px -2615px; width: 300px; height: 518px; }
.cardrk { background-position: -2140px -2615px; width: 300px; height: 518px; }
.cardrq { background-position: -5px -3138px; width: 300px; height: 518px; }
.cards10 { background-position: -310px -3138px; width: 300px; height: 518px; }
.cards2 { background-position: -615px -3138px; width: 300px; height: 518px; }
.cards3 { background-position: -920px -3138px; width: 300px; height: 518px; }
.cards4 { background-position: -1225px -3138px; width: 300px; height: 518px; }
.cards5 { background-position: -1530px -3138px; width: 300px; height: 518px; }
.cards6 { background-position: -1835px -3138px; width: 300px; height: 518px; }
.cards7 { background-position: -2140px -3138px; width: 300px; height: 518px; }
.cards8 { background-position: -5px -3661px; width: 300px; height: 518px; }
.cards9 { background-position: -310px -3661px; width: 300px; height: 518px; }
.cardsa { background-position: -615px -3661px; width: 300px; height: 518px; }
.cardsj { background-position: -920px -3661px; width: 300px; height: 518px; }
.cardsk { background-position: -1225px -3661px; width: 300px; height: 518px; }
.cardsq { background-position: -1530px -3661px; width: 300px; height: 518px; }
.cardt10 { background-position: -1835px -3661px; width: 300px; height: 518px; }
.cardt3 { background-position: -2140px -3661px; width: 300px; height: 518px; }
.cardt4 { background-position: -5px -4184px; width: 300px; height: 518px; }
.cardt5 { background-position: -310px -4184px; width: 300px; height: 518px; }
.cardt6 { background-position: -615px -4184px; width: 300px; height: 518px; }
.cardt7 { background-position: -920px -4184px; width: 300px; height: 518px; }
.cardt8 { background-position: -1225px -4184px; width: 300px; height: 518px; }
.cardt9 { background-position: -1530px -4184px; width: 300px; height: 518px; }
.cardta { background-position: -1835px -4184px; width: 300px; height: 518px; }
.cardtj { background-position: -2140px -4184px; width: 300px; height: 518px; }
.cardtk { background-position: -5px -4707px; width: 300px; height: 518px; }
.cardtq { background-position: -310px -4707px; width: 300px; height: 518px; }

.playing-card {
    border: 1px solid darkgray;
    -moz-box-shadow: .2em .2em .5em #ccc;
    -webkit-box-shadow: .2em .2em .5em #ccc;
    box-shadow: .2em .2em .5em #ccc;
    background-color: rgba(0, 0, 0, 0.2);
    bottom: 0;
    top: 0;
}

.card-rounded {
    border-radius: 44px;
}

.playingCardsContainer {
    position: fixed;
    bottom: 0;
    border-top: 4px double #ccc; 

    background-image: radial-gradient(#ccc 20%, transparent 20%), radial-gradient(#fafafa 20%, transparent 20%);
    background-color: #eaeaea;
    background-position: 0 0, 50px 50px;
    background-size: 100px 100px;
    padding-left: 20px;

    width: 100%;
}

.playingCards {
    display: table;
    width: 100%;
    padding-top: 20px;
    height: 250px;
}

.playingCards:after {
    content: "";
    display: table;
    clear: both;
}

.playingCards .hand li {
    background-blend-mode: color;
}

.playingCards .hand .active {
    background-blend-mode: normal;
    border: 1px solid #000;
}

.playingCards .hand .active:hover {
    margin-top: -10px;
    cursor: pointer;
}

.scaled {
    transform-origin: top left;
    transform: scale(0.4);
}

.scaled-big {
    transform: scale(0.9);
}

.playingCards ul.table,
.playingCards ul.hand,
.playingCards ul.deck {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1.5em 0;
    position: relative;
}
.playingCards ul.table li,
.playingCards ul.hand li,
.playingCards ul.deck li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    float: left;
}

.playingCards ul.hand li,
.playingCards ul.deck li {
    position: absolute;
    display: inline-block;
}

.playingCards ul.hand li:nth-child(1)  { left: 0; }
.playingCards ul.hand li:nth-child(2)  { left: 3em; }
.playingCards ul.hand li:nth-child(3)  { left: 6em; }
.playingCards ul.hand li:nth-child(4)  { left: 9em; }
.playingCards ul.hand li:nth-child(5)  { left: 12em; }
.playingCards ul.hand li:nth-child(6)  { left: 15em; }
.playingCards ul.hand li:nth-child(7)  { left: 18em; }
.playingCards ul.hand li:nth-child(8)  { left: 21em; }
.playingCards ul.hand li:nth-child(9)  { left: 24em; }
.playingCards ul.hand li:nth-child(10) { left: 27em; }
.playingCards ul.hand li:nth-child(11) { left: 30em; }
.playingCards ul.hand li:nth-child(12) { left: 33em; }
.playingCards ul.hand li:nth-child(13) { left: 36em; }
.playingCards ul.hand li:nth-child(14) { left: 39em; }
.playingCards ul.hand li:nth-child(15) { left: 42em; }
.playingCards ul.hand li:nth-child(16) { left: 45em; }
.playingCards ul.hand li:nth-child(17) { left: 48em; }
.playingCards ul.hand li:nth-child(18) { left: 51em; }
.playingCards ul.hand li:nth-child(19) { left: 54em; }
.playingCards ul.hand li:nth-child(20) { left: 57em; }
.playingCards ul.hand li:nth-child(21) { left: 60em; }
.playingCards ul.hand li:nth-child(22) { left: 63em; }
.playingCards ul.hand li:nth-child(23) { left: 66em; }
.playingCards ul.hand li:nth-child(24) { left: 69em; }
.playingCards ul.hand li:nth-child(25) { left: 72em; }
.playingCards ul.hand li:nth-child(26) { left: 75em; }